define("discourse/plugins/discourse-directoryopus/discourse/templates/connectors/user-card-post-names/directoryopus-link-status-card", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.siteSettings.directoryopus_enabled}}
  	{{#if this.opusLinkAnything}}
  		<div class="directoryopus-link-status">
  			{{#if this.opusLinkClass}}
  				<span class="{{this.opusLinkClass}}">{{d-icon this.opusLinkIcon}} {{this.opusLinkText}}</span>
  			{{/if}}
  		</div>
  	{{/if}}
  {{/if}}
  
  */
  {
    "id": "uSaM7ork",
    "block": "[[[41,[30,0,[\"siteSettings\",\"directoryopus_enabled\"]],[[[41,[30,0,[\"opusLinkAnything\"]],[[[1,\"\\t\\t\"],[10,0],[14,0,\"directoryopus-link-status\"],[12],[1,\"\\n\"],[41,[30,0,[\"opusLinkClass\"]],[[[1,\"\\t\\t\\t\\t\"],[10,1],[15,0,[29,[[30,0,[\"opusLinkClass\"]]]]],[12],[1,[28,[35,3],[[30,0,[\"opusLinkIcon\"]]],null]],[1,\" \"],[1,[30,0,[\"opusLinkText\"]]],[13],[1,\"\\n\"]],[]],null],[1,\"\\t\\t\"],[13],[1,\"\\n\"]],[]],null]],[]],null]],[],false,[\"if\",\"div\",\"span\",\"d-icon\"]]",
    "moduleName": "discourse/plugins/discourse-directoryopus/discourse/templates/connectors/user-card-post-names/directoryopus-link-status-card.hbs",
    "isStrictMode": false
  });
});