define("discourse/plugins/discourse-directoryopus/discourse/templates/connectors/composer-fields/directoryopus-composer-help", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.siteSettings.directoryopus_enabled}}
  	{{#if this.siteSettings.directoryopus_composer_help_title}}
  		{{#if this.siteSettings.directoryopus_composer_help_url}}
  			<div class="directoryopus-composer-help">
  				<a href="{{this.siteSettings.directoryopus_composer_help_url}}">{{d-icon 'circle-info'}} {{this.siteSettings.directoryopus_composer_help_title}}</a>
  			</div>
  		{{/if}}
  	{{/if}}
  {{/if}}
  
  */
  {
    "id": "3col7p1b",
    "block": "[[[41,[30,0,[\"siteSettings\",\"directoryopus_enabled\"]],[[[41,[30,0,[\"siteSettings\",\"directoryopus_composer_help_title\"]],[[[41,[30,0,[\"siteSettings\",\"directoryopus_composer_help_url\"]],[[[1,\"\\t\\t\\t\"],[10,0],[14,0,\"directoryopus-composer-help\"],[12],[1,\"\\n\\t\\t\\t\\t\"],[10,3],[15,6,[29,[[30,0,[\"siteSettings\",\"directoryopus_composer_help_url\"]]]]],[12],[1,[28,[35,3],[\"circle-info\"],null]],[1,\" \"],[1,[30,0,[\"siteSettings\",\"directoryopus_composer_help_title\"]]],[13],[1,\"\\n\\t\\t\\t\"],[13],[1,\"\\n\"]],[]],null]],[]],null]],[]],null]],[],false,[\"if\",\"div\",\"a\",\"d-icon\"]]",
    "moduleName": "discourse/plugins/discourse-directoryopus/discourse/templates/connectors/composer-fields/directoryopus-composer-help.hbs",
    "isStrictMode": false
  });
});